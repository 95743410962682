var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3 pb-3"},[_vm._l((_vm.customerOrders),function(customerOrder){return _c('v-container',{key:customerOrder.id,staticClass:"order",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"order-selection pl-1 pr-0",attrs:{"align-self":"center"}},[_c('v-simple-checkbox',{attrs:{"disabled":_vm.main.loading},on:{"input":function($event){return _vm.selectionChanged(customerOrder)}},model:{value:(customerOrder.selected),callback:function ($$v) {_vm.$set(customerOrder, "selected", $$v)},expression:"customerOrder.selected"}})],1),(_vm.screenSmall)?_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"subtitle-2"},on),[_vm._v(" "+_vm._s(customerOrder.uniqueNo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.order-no')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2"},on),[_vm._v(" "+_vm._s(_vm.formatDate(customerOrder.orderDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.order-date')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"blue--text darken-2 body-2 py-1"},on),[_vm._v(" "+_vm._s(_vm.formatStatus(customerOrder.status))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.status')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"red--text darken-1 body-2"},on),[_vm._v(" "+_vm._s(_vm.formatAmount(customerOrder.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])])],1):_vm._e(),(!_vm.screenSmall)?_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"subtitle-2"},on),[_vm._v(" "+_vm._s(customerOrder.uniqueNo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.order-no')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"body-2"},on),[_vm._v(" "+_vm._s(_vm.formatDate(customerOrder.orderDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.order-date')))])])],1):_vm._e(),(!_vm.screenSmall)?_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"blue--text darken-2 body-2 py-1"},on),[_vm._v(" "+_vm._s(_vm.formatStatus(customerOrder.status))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.status')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"red--text darken-1 body-2"},on),[_vm._v(" "+_vm._s(_vm.formatAmount(customerOrder.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])])],1):_vm._e(),_c('v-col',{staticClass:"text-right",attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.expand(customerOrder)}}},[_vm._v(" "+_vm._s(customerOrder.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)],1)],1),(customerOrder.expanded)?_c('v-row',{staticClass:"pt-0"},[_c('v-divider')],1):_vm._e(),(customerOrder.expanded)?_c('v-row',[_c('v-col',{staticClass:"pr-1"},[_c('MobileConfirmedOrderDetailView',{attrs:{"properties":_vm.properties,"alertDialog":_vm.alertDialog,"customerOrder":customerOrder}})],1)],1):_vm._e()],1)}),(!_vm.customerOrders.length)?_c('v-container',{staticClass:"order-no-data",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center body-2 my-2"},[_vm._v(_vm._s(_vm.$t("$vuetify.noDataText")))])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }